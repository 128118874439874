import React, { useMemo } from 'react'
import { Footer } from '../../Footer/footer'
import { FooterButton } from '../../Footer/footerButton'
import Grid from '@material-ui/core/Grid'
import { FooterAccordion } from '../../Footer/footerAccordion'
import { FooterLinksType } from '../../Footer/footerBottom'
import { nonNull, isNotNull } from '../../../utils/nonNull'
import type { FooterGlobals } from '../../../globals/context'
import { useMediaQuery } from '@material-ui/core'
import { theme } from '../../../theme'

export type FooterPartialProps = FooterGlobals

export const chunk = <T extends unknown>(array: T[], size: number): T[][] => {
  return array.reduce((carry, node, i) => {
    if (i % size === 0) { // New row
      carry.push([])
    }

    const row = carry[carry.length - 1]

    if (row) {
      row.push(node)
    }

    return carry
  }, [] as T[][])
}

export const FooterFragment: React.FC<FooterPartialProps> = ({ nodes, globalSet }) => {
  if (!globalSet || globalSet.__typename !== 'footer_GlobalSet' || !nodes) {
    return null
  }

  const desktop = useMediaQuery(theme.breakpoints.up('lg'))
  const navRows = useMemo(() => chunk(nodes, 2), [nodes])
  const buttonRows = useMemo(() => chunk(globalSet.footerQuickLinks || [], 2), [globalSet.footerQuickLinks])
  const [leftLinks, rightLinks] = useMemo(() => {
    return nonNull(globalSet.footerSubLinks || []).reduce((carry, link, i) => {
      // @ts-ignore
      carry[i % 2].push({
        url: link.footerSubLinkUrl?.url || '#',
        text: link.footerSubLinktext || '',
        target: link.footerSubLinkUrl?.target === '_blank' ? '_blank' : '_self',
      })

      return carry
    }, [[], []] as [FooterLinksType[], FooterLinksType[]])
  }, [globalSet.footerSubLinks])

  return (
    <Footer>
      <Footer top>
        {globalSet.footerQuickLinks && globalSet.footerQuickLinks.length > 0 && (
          <Footer firstRow>
            {buttonRows.map((row, i) => (
              <FooterButton key={i}>
                {nonNull(row).map((link, i) => {
                  return (
                    <FooterButton key={i} button icon={link.footer_quickLink_icon?.[0]?.url ?? undefined} href={link.footer_quickLink_Url?.url ?? undefined} target={link.footer_quickLink_Url?.target === '_blank' ? '_blank' : '_self'}>
                      <FooterButton title>{link.footer_quick_link_title}</FooterButton>
                      {link.footer_quickLink_Url && (
                        <FooterButton subtitle>{link.footer_quickLink_Url?.customText}</FooterButton>
                      )}
                    </FooterButton>
                  )
                })}
              </FooterButton>
            ))}
          </Footer>
        )}

        <Footer secondRow socialLinks={globalSet.socialLinks && globalSet.socialLinks.length> 0 && isNotNull(globalSet.socialLinks[0])? globalSet.socialLinks[0]: undefined}>
          {navRows.map((nodes, i) => (
            <Grid
              key={i}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-around"
            >
              {nonNull(nodes).map((node, i) => node.__typename === 'footerNavigation_Node' ? (
                <FooterAccordion key={i}>
                  <FooterAccordion title href={node.url || '#'}>{node.title}</FooterAccordion>
                  {node.children && node.children.length > 0 && (
                    <FooterAccordion list>
                      {!desktop && (
                        <FooterAccordion item href={node.url || '#'}>Overview</FooterAccordion>
                      )}
                      {nonNull(node.children).map((node, i) => (
                        <FooterAccordion key={i} item href={node.url || '#'}>{node.title}</FooterAccordion>
                      ))}
                    </FooterAccordion>
                  )}
                </FooterAccordion>
              ) : null)}
            </Grid>
          ))}
        </Footer>
      </Footer>
      <Footer
        bottom
        acknowledgement={globalSet.simpleText || ''}
        copyrightText={[
          `© Copyright ${new Date().getFullYear()} Credit Union SA Ltd`,
          'ABN 36 087 651 232 ',
          'AFSL/Australian Credit License Number 241066',
        ]}
        leftLinks={leftLinks}
        rightLinks={rightLinks}
      />
    </Footer>
  )
}
